import React from 'react';
import { Box, Grid } from '@material-ui/core';

import ImagesListOfParticipants from '../imagesSettings/Tour/imagesListOfParticipants';

const LandingPagesListOfParticipants = () => {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item lg={12} md={12} xs={12}>
          <Box mt={2}>
            <ImagesListOfParticipants />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default LandingPagesListOfParticipants;
