import { graphql } from 'gatsby';
import * as React from 'react';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { getImage } from 'gatsby-plugin-image';
import { Box, ListItem, ListItemText, Typography } from '@material-ui/core';
import Seo from '../components/seo';
import { LandingPageLayout } from '../components/layout';
import { Section } from '../components/layout/Section';

import LandingPagesListOfParticipants from '../components/LandingPages/landingPagesListOfParticipants';
import { UmoPromote } from '../components/UmoPromote';
import { BoxCompare } from '../components/BoxCompare';

const PageProofInstraction = ({ pageContext, data }) => {
  const { bannerImageCall } = data;
  const { t } = useTranslation(['proof-of-instruction']);
  const bannerImage = getImage(bannerImageCall);
  return (
    <>
      <Seo title={t('meta.title')} description={t('meta.description')} lang={pageContext.language} />

      <LandingPageLayout
        bannerImage={bannerImage}
        bannerTitle={t('banner.title')}
        bannerDescription={t('banner.description')}
      >
        <Section heading={t('heading.proofOfInstruction')}>
          <Typography>{t('content.proofOfInstruction')}</Typography>
          <Section headingVariant="h3" heading={t('heading.specificationsProofOfInstruction')}>
            <Typography>{t('content.detailsProofOfInstruction')}</Typography>

            <ul>
              {[
                t('trans.Proof of instruction Details-1'),
                t('trans.Proof of instruction Details-2'),
                t('trans.Proof of instruction Details-3'),
                t('trans.Proof of instruction Details-4'),
                t('trans.Proof of instruction Details-5'),
                t('trans.Proof of instruction Details-6'),
                t('trans.Proof of instruction Details-7'),
              ].map((text) => {
                return (
                  <ListItem dense style={{ display: 'list-item' }} key={text}>
                    <ListItemText>{text}</ListItemText>
                  </ListItem>
                );
              })}
            </ul>
          </Section>

          <Section headingVariant="h3" heading={t('heading.downloadProofOfInstruction')}>
            <Typography>
              <Trans
                t={t}
                components={[
                  <Link key="0" to={t('content.detailsProofOfInstructionLink')} title="Unterweisungsarchiv" />,
                ]}
                i18nKey="content.detailsProofOfInstructionLinktext"
              />
            </Typography>
            <Box>
              <LandingPagesListOfParticipants />
            </Box>
          </Section>

          <Typography>{t('content.detailsProofOfInstruction1')}</Typography>
        </Section>

        <Section heading={t('heading.umoProofOfInstruction')}>
          <Typography>
            <Trans
              t={t}
              components={[<Link key="0" to="/instruction-archive" />]}
              i18nKey="content.umoProofOfInstruction"
            />
          </Typography>
          <Typography>{t('content.umoProofOfInstruction2')}</Typography>
          <Typography>{t('content.umoProofOfInstruction3')}</Typography>
          <Section headingVariant="h3" heading={t('heading.workshopsNachweis')}>
            <Typography>{t('content.workshopsNachweis')}</Typography>
            <BoxCompare
              pros={[
                t('content.workshopsNachweisVorteil'),
                t('content.workshopsNachweisVorteil2'),
                t('content.workshopsNachweisVorteil3'),
                t('content.workshopsNachweisVorteil4'),
                t('content.workshopsNachweisVorteil5'),
              ]}
              cons={[t('content.workshopsNachweisNachteil')]}
            />
            <UmoPromote />
          </Section>
        </Section>
      </LandingPageLayout>
    </>
  );
};
export default PageProofInstraction;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    bannerImageCall: file(relativePath: { eq: "storybuero.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2000, quality: 50, webpOptions: { quality: 70 })
      }
    }
  }
`;
